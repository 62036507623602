<template>
  <vue-scroll class="terms-page align-vertical-middle">
    <div class="flex column">
      <div class="box center">
        <h2 class="accent-text">RollDXB terms and conditions</h2>
      </div>
      <div class="box">
        <h2>PARK SAFETY RULES</h2>
        <p>1.  Do not participate in any activities above your skill set. </p>
        <p>2.  Aggressive skating should be performed only during the dedicated sessions, and by professional and advanced skaters only.</p>
        <p>3.  Parent/Guardian supervision is required for children under the age of 14.  It is mandatory for children to wear appropriate safety gear (i.e., helmet, knee/elbow pads & wrist support). </p>
        <p>4.  No littering, graffiti, gum, and smoking allowed in Rolldxb.  Any damage to the property will incur a minimum penalty of AED 3,500 or the actual amount of the repair.</p>
        <p>5.  No eating and drinking while on the skating floor. </p>
        <p>6.  Alcohol will only be allowed at licensed events.</p>
        <p>7.  Do not hinder traffic while skating on the skating floor.</p>
        <p>8.  All skaters should move in one direction.</p>
        <p>9.  Be alert, violators of the safety rules will be restricted from the park.</p>

        <h3>COVID-19 GUIDELINE</h3>
        <p>1.  All guests/participants must wear a mask at all times.  You may be allowed to lower/remove your mask only when engaged in skating/performing a dance or stunt routine.</p>
        <p>2.  Regularly wash your hands with soap and water or use hand sanitizer accessible inside the facility.</p>
        <p>3.  Keep physical distance up to 3 meters.</p>
        <p>4.  Any person exhibiting COVID-19 symptoms will not be allowed to participate into the premises.</p>

        <h2>RELEASE AND WAIVER OF LIABILITY AND INDEMNITY AGREEMENT</h2>
        <p><i>(Read Carefully Before Confirming)</i></p>
        <p>I, on my own behalf and on behalf of any person who is a minor by law, and who is in my care or legal guardianship named as a participant below agree that in consideration of being permitted to participate in any skating activity, event or program organized by [ROLL SPORTS & AMUSEMENT TRACKS LLC] a.k.a. (“Rolldxb”) and/or being permitted to enter the premises of Rolldxb (defined as any area where admittance by the general public is controlled by Rolldxb) agree as follows: </p>
        <p>Prior to participating in any skating activity, event or program, I agree that I am responsible for inspecting and instructing minors under my care or legal guardianship, to inspect the facilities and equipment I/ We use, and if any of us believe that anything is unsafe, I shall immediately advise Rolldxb officials of such condition and shall discontinue our participation. For the avoidance of doubt, if at any time I/ We feel anything to be UNSAFE for myself or for the minors in my care or legal guardianship, I/ We will immediately take all precautions to avoid the unsafe area and REFUSE TO PARTICIPATE further.</p>
        <p>I/ We fully understand and acknowledge that: </p>
        <p>(a) there are inherent risks and dangers associated with participation in skating events and activities which could result in bodily injury, partial and/or total disability, paralysis and death; </p>
        <p>(b) the social and economic losses and/or damages could result from these risks and dangers described above, could be severe; </p>
        <p>(c) these risks and dangers may be caused by the action, inaction or negligence of any participant or the action, inaction or negligence of others, including, but not limited to, Rolldxb, its employees, directors, agents, instructors, officials, managers, officers and contractors (the “Releasees”); and </p>
        <p>(d) there may be other risks not known to us or are not reasonable foreseeable at this time. </p>
        <p>I/ WE ACCEPT, ASSUME RESPONSIBILITY FOR SUCH RISKS AS STATED IN PARAGRAPH 2 ABOVE AND THE CLAIMS, LOSSES AND/OR DAMAGES RELATED TO OR ARISING FROM THEM SUCH AS DAMAGE TO PROPERTY, INJURY, DISABILITY, PARALYSIS, DEATH, HOWSOEVER CAUSED AND WHETHER CAUSED IN WHOLE OR IN PART BY THE NEGLIGENCE OF THE RELEASEES, AND ACCORDINGLY I/WE HEARBY RELEASE, WAIVE, DISCHARGE FROM ALL LIABILITY THE RELEASEES IN MY PERSONAL CAPACITY AND/ OR IN ANY OTHER CAPACITY SUCH AS REPRESENTATIVES, ASSIGNS, EXECUTORS, HEIRS AND NEXT TO KIN. </p>
        <p>I/ We Hereby also expressly acknowledges that INJURIES MAY BE COMPOUNDED OR INCREASED BY NEGLIGENT RESCUE OPERATIONS OR PROCEDURES OF THE RELEASEES.</p>
        <p>I/We Hereby acknowledge that PHOTO & VIDEO may be used for social media marketing or other marketing activities purposes.</p>
        <p>I shall indemnify and hold the Releasees harmless from any and all claims, losses, damages, judgments, damages, liabilities, costs and expenses including reasonable attorney’s fees incurred by the Releases or due to third party action against the Releasees arising out of or related to my action or the action of the minor children in my care or legal guardianship including negligence, misuse, error or omission on my part.</p>
        <p>I the undersigned person, on behalf of myself and on behalf of a minor in my care or legal guardianship execute this Release and Waiver of Liability and Indemnity Agreement and agree to indemnify and hold the Releases harmless as a result of the breach of this Agreement. </p>
        <p>I/ We further expressly agree that this Release and Waiver of Liability and Indemnity Agreement is intended to be as broad and inclusive as is permitted by the law of the UAE or the Emirate of Dubai and that if any portion is held invalid, it is agreed that the balance shall, notwithstanding continue in full legal force and effect.</p>
        <p><b>I HAVE READ THIS RELEASE AND WAIVER OF LIABILITY AND INDEMNITY AGREEMENT, AND FULLY UNDERSTAND THE ASSUMPTION OF RISK AND INDEMNITY OBLIGATIONS AND ITS TERMS GENERALLY. I UNDERSTAND THAT I HAVE GIVEN UP SUBSTANTIAL RIGHTS BY SIGNING IT ON BEHALF OF MYSELF AND THE MINORS IN MY CARE OR LEGAL GUARDIANSHIP, AND HAVE SIGNED IT FREELY AND VOLUNTARILY WITHOUT ANY INDUCEMENT, ASSURANCE, OR GUARANTEE BEING MADE TO ME AND INTEND MY SIGNATURE TO BE COMPLETE AND UNCONDITIONAL RELEASE OF ALL LIABILITY TO THE GREATEST EXTENT ALLOWED BY LAW. </b></p>
      </div>
    </div>
    <div class="box mb-30 flex gaps">
      <el-button type="primary" size="small" @click="back" class="signin-btn">
        I AGREE
      </el-button>
    </div>
  </vue-scroll>
</template>

<script>
export default {
  name: 'Register',
  data () {
    return {}
  },
  methods: {
    back () {
      this.$onCommandParams('register')
    },
    qr () {
      this.$onCommandParams('qr')
    }
  },
  activated () {
    this.$nextTick(() => {
      this.$store.commit('setSplashScreen', false)
    })
  }
}
</script>

<style lang="scss">
@import '../assets/scss/_variables';

.terms-page {
  margin-left: -30px;
  margin-right: -30px;
}

@media (max-width: 768px) {
  .layout-container .container .view.terms-page {
    margin-left: -5px;
    margin-right: -5px;
    max-width: calc(100% + 10px);
  }
}
</style>
